import Service from "../Service";
const resource = "PackingShipment/"

export default {

    save(obj, requestID) {
        return Service.post(resource + "Save", obj, {
            params: { requestID: requestID },
        });
    },

    pagination(obj, requestID) {
        return Service.post(resource + "paginationPackingList", obj, {
            params: { requestID: requestID }
        });
    },

    getPrePacking(PckID, requestID) {
        return Service.post(resource + "getPrePacking", "", {
            params: { PckID: PckID, requestID: requestID }
        });
    },

    downloadExcelReportPrePacking(obj, requestID) {

        return Service.post(resource + "downloadExcelReportPrePacking", obj, {
            responseType: "blob",
            headers: {
                "Content-Type": "application/json",
            },
            params: { requestID }
        })
    },

    generatedPrePackSenasa(obj, requestID) {
        console.log('sssssssssssssssssssss ', obj);
        return Service.post(resource + "generatedPrePackingSenasa", obj, {
            params: { requestID: requestID }
        });
    },

    downloadPdfReportSenasa(obj, requestID) {
        return Service.post(resource + "downloadPdfReportSenasa", obj, {
            responseType: "blob",
            headers: {
                "Content-Type": "application/json",
            },
            params: { requestID }
        })
    },

    updateprepackingdetail(obj, requestID) {
        return Service.post(resource + "updateprepackingdetail", obj, {
            params: { requestID: requestID }
        });
    },

    updateDocStatus(obj, requestID) {
        return Service.post(resource + "updateDocStatus", obj, {
            params: { requestID: requestID }
        });
    },

    getPrePackingList(parameters,requestID) {
        return Service.post(resource + "getPrePackingList", parameters, {
            params: { requestID: requestID },
        });
    },
};