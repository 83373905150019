<template>
	<div>

		<v-card>
			<v-container>
				<v-row justify="center">
					<b><h2 class="mt-3 ml-6">EMBARQUE {{itemsDetail[0].TypeDestinyPacking}}</h2></b>
                    
				</v-row>
                <v-row>
					<v-col class="pb-0">
						<v-divider></v-divider>
					</v-col>
				</v-row>
                
                <v-row justify="center" style="text-shadow: 0 0 black;">
					<v-col cols="3"><h4 class="mt-3 ml-6">Embarque: {{items.PckShipmentNumber}}</h4></v-col>
                    <v-col cols="3"><h4 class="mt-3 ml-6">Semana: {{itemsDetail[0].PltWeek}}</h4></v-col>
                    <v-col cols="3"><h4 class="mt-3 ml-6">Fecha: {{items.PckDate}}</h4></v-col>
                    <v-col cols="3">
						<h4 class="mt-3 ml-6">
							Orden de Venta: 
							<s-text @keyupEnter="enter($event)" v-model="OrdID">

							</s-text>
						</h4>
					</v-col>
				</v-row>

				<v-row>
					<v-col class="pb-0">
						<v-divider></v-divider>
					</v-col>
				</v-row>

				<v-row>
					<v-col lg="12" class="pt-0">
						<v-data-table
                            :items="itemsDetail"
									item-key="PltID"
									dense
									:headers="headers"
									:items-per-page="-1"
									disable-sort
                                    hide-default-footer
                        >

                        </v-data-table>
					</v-col>
				</v-row>
                 <v-row justify="center" style="text-shadow: 0 0 black;">
                    <v-col cols="8" align="right"><h3 >TOTAL CAJAS</h3></v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="4" align="right"><h3 >{{sumTotalBoxe}}</h3></v-col>
				</v-row>
			</v-container>
		</v-card>
	</div>

</template>

<script>
	import PackingService from '../../../services/FreshProduction/PackingService';


	export default {
        props: {
            items: null
        },
		components: {},

		data () {
            return {
                sumTotalBoxe: 0,
                headers:[
                    {text:"Fecha", value: "PltDate", width: 100},
                    {text:"Turno", value: "TypeTurnName", width: 100},
                    {text:"Tipo Cultivo", value: "TypeCropName", width: 100},
                    {text:"N° Pallet", value: "PltID", width: 100},
                    {text:"Variedad", value: "VrtName", width: 100},
                    {text:"Calibre", value: "TypeCaliberName", width: 100},
                    {text:"FPB", value: "PltFruitxBoxe", width: 100},
                    {text:"Lotes Prod.", value: "RcfID", width: 300},
                    {text:"Tipo de Caja", value: "ItemName", width: 400},
					{text:"Tipo de Empacado", value: "TypePresentationPackingName", width: 100},
                    {text:"Procedencia", value: "ZonName", width: 100},
                    {text:"Cliente", value: "CardName", width: 400},
                    {text:"Peso Caja (KG)", value: "PltWeightBoxe", width: 100},
                    {text:"Malla", value: "PltMesh", width: 100},
                    {text:"Cajas x Pallet", value: "UwoMaterialListNumberBoxes", width: 100},					
                ],
				OrdID: "",
				itemsDetail: []
            }
        },

		methods: {
			enter(item) {
				this.$fun.alert("¿Seguro de actualizar?", "question")
				.then(r => {
					if(r.value){
						PackingService.updateord(this.OrdID, this.items.OrdIDOLD, this.$fun.getUserID())
						.then(resp => {
							if(resp.status == 200){
								this.$fun.alert("Datos actualizados correctamente", "success");
								this.$emit('close')
							}
						})
					}
				})
			}
		},

		created() {

			if(this.items.itemsDetail.length > 0){

				this.itemsDetail = this.items.itemsDetail
				this.itemsDetail.forEach(element => {
					this.sumTotalBoxe += element.UwoMaterialListNumberBoxes
				});

				this.OrdID = this.items.OrdID;
				this.items.OrdIDOLD = this.OrdID

				console.log("recibiendoCabecera", this.items);
			}
			
		}
	};
</script>
